import React, { Component } from "react";
import { withRouter } from "react-router";
import strings from "../config/strings";
import EditCounter from "../components/EditCounter";
import ProjectUtils from '../utils/projectUtils'
import Utils from "../utils/utils"
import Image from "../components/Image"
import ProjectService from "../services/ProjectService";
import { HEADER_HEIGHT } from '../config/Constants'
import { Beforeunload } from 'react-beforeunload';
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  BackHandler,
  Linking,
  Dimensions
} from 'react-native';

const BORDER_WIDTH = 0.5
const SECONDARY_COLOR = "#5A3"

class EditCounterList extends Component {

  constructor(props) {
    super(props);



    this.projInProgress = this.props.project;

    console.log("project passed to counter list", this.props.project)

    this.numPart = this.projInProgress.current_partie;
    this.state = {
      editingCounter: null, adding: false,
      counters: this.getCurrentCountersOrdered(),
      // averageStitches: this.projInProgress.averageStitches,
      expertMode: false,
      loadingSave: false

    };

    this.confirmActions = {
      deletePartie: {
        message: strings.delete_part,
        action: this.deletePartie.bind(this)
      },
      deleteCounter: {
        message: strings.delete + "?",
        action: this.deleteCounter.bind(this)
      },
      resetCounter: {
        message: strings.reset_counter + "?",
        action: this.resetCounter.bind(this)
      },
    }

    this.lastRequest = null

  }

  /* componentDidMount() {

     const { match, location, history } = this.props;

     this.unlistenHistory = history.block(({ pathname }) => {

       //if (this.hasChanged()) {
         return strings.discard_changes
       //}
     });


   }

   componentWillUnmount() {
     setTimeout(() => this.unlistenHistory(), 20)

   }*/



  handleBackPress = () => {
    this.onBack(); // works best when the goBack is async
    return true;
  }


  sendUpdateToServer = (closeAfter, refreshPageAfter = false) => {


    //sometimes send twice the same request at the same time, which can create duplicate counters
    if (this.lastRequest && this.lastRequest == JSON.stringify(this.projInProgress.counters))
      return
    this.lastRequest = JSON.stringify(this.projInProgress.counters)
    //check if no duplicates
    var uniqueIds = []
    for (var i = 0; i < this.projInProgress.counters.length; i++) {
      if (uniqueIds.indexOf(this.projInProgress.counters[i].unique_id_on_client) != -1) {
      //  alert("Bug detected. Please contact us at contact@annapurn.app and explain what you were doing before saving so we can fix that issue. thanks!\n")
        console.log("Bug detected")
        //return
      }
      uniqueIds.push(this.projInProgress.counters[i].unique_id_on_client)
    }

    this.projInProgress.updated_at_on_client = Utils.getTime();
    
    if (closeAfter)
      this.setState({ loadingSave: true })
    ProjectUtils.sendUpdateToServer(this.projInProgress)
      .then((response) => {
        this.lastRequest = null
        this.setCountersId(response.data)
        if (closeAfter)
          this.props.onClose()

        if (refreshPageAfter)
          window.location.reload()
      })

      .catch((error) => {
        this.lastRequest = null
        alert(error.message)
        this.setState({ loadingSave: false })
      });
  }


  onBack() {

    if ((this.state.adding || this.state.editingCounter) && this.editCounterRef) {
      this.editCounterRef.onBack()
      return
    }
    this.history.goBack()
  }


  onClose() {
    this.setState({ editingCounter: null, adding: false });
  }

  getCurrentCountersOrdered() {

    return ProjectUtils.getNonDeletedCounters(this.projInProgress, this.numPart).sort((a, b) => (a.position > b.position) ? 1 : -1)
  }

  getNbTabs() {

    return ProjectUtils.getNbParts(this.projInProgress)
  }


  setCountersId(projectFromServer) {

    for (var i = 0; i < this.projInProgress.counters.length; i++) {
      var counter = this.projInProgress.counters[i]

      if (!counter.id) {
        for (var y = 0; y < projectFromServer.counters.length; y++) {
          var serverCounter = projectFromServer.counters[y]

          if (serverCounter.unique_id_on_client === counter.unique_id_on_client) {
            counter.id = serverCounter.id
            break
          }
        }
      }
    }

    this.setState({ counters: this.getCurrentCountersOrdered() })
  }



  askDeletePartie() {
    this.setState({ confirmAction: 'deletePartie' })
    // confirmAlert({
    //   title: '',
    //   message: strings.delete_part,
    //   buttons: [
    //     {
    //       label: strings.cancel,
    //       onClick: () => { }
    //     },
    //     {
    //       label: 'OK',
    //       onClick: () => this.deletePartie()
    //     }
    //   ]
    // });

  }

  deletePartie() {
    var currentCounters = this.getCurrentCountersOrdered();

    for (var x = this.numPart; x < ProjectUtils.getNbParts(this.projInProgress); x++) {
      currentCounters = ProjectUtils.getNonDeletedCounters(this.projInProgress, x)
      for (var i = 0; i < currentCounters.length; i++) {

        if (x == this.numPart)
          currentCounters[i].deleted = 1;
        else
          currentCounters[i].num_part = x - 1
      }
    }



    if (this.numPart + 1 > ProjectUtils.getNbParts(this.projInProgress)) {

      this.numPart = this.numPart - 1;

    }

    if (this.projInProgress.current_partie >= ProjectUtils.getNbParts(this.projInProgress)) {
      this.projInProgress.current_partie = this.projInProgress.current_partie - 1;
    }



    this.sendUpdateToServer(false, true)


    this.setState({ counters: this.getCurrentCountersOrdered(), confirmAction: null})

  }


  askDeleteCounter(counter) {
    this.setState({ confirmAction: 'deleteCounter', counterForAction: counter})
    // confirmAlert({
    //   title: '',
    //   message: strings.delete + "?",
    //   buttons: [
    //     {
    //       label: strings.cancel,
    //       onClick: () => { }
    //     },
    //     {
    //       label: 'OK',
    //       onClick: () => this.deleteCounter(counter)
    //     }
    //   ]
    // });


  }

  goToFAQ() {

    if (strings.lang == 'en') {
      Linking.openURL("http://rowcounterapp.com/faq.html")
      return
    }
    Alert.alert("", strings.faq_translated + '\n\n' + strings.or + ' ' + strings.need_help_contact.toLowerCase(), [
      {
        text: strings.cancel,


      },
      {
        text: strings.yes,
        style: "cancel",
        onPress: () => {

          var url = `https://translate.google.com/translate?sl=auto&tl=${strings.lang}&u=http%3A%2F%2Frowcounterapp.com%2Ffaq.html`;
          Linking.openURL(url);

        },
      }
    ]);



  }


  deleteCounter() {
    var counter = this.state.counterForAction
    counter.deleted = 1;

    var counters = this.getCurrentCountersOrdered()


    for (var i = 0; i < counters.length; i++) {

      counters[i].position = i;

      counters[i].updated_at_on_client = Utils.getTime();
    }


    this.sendUpdateToServer(false)

    this.setState({ counters: this.getCurrentCountersOrdered(), confirmAction: null, counterForAction: null})

  }


  askResetCounter(counter) {
    this.setState({ confirmAction: 'resetCounter', counterForAction: counter})
    // confirmAlert({
    //   title: '',
    //   message: strings.reset_counter + "?",
    //   buttons: [
    //     {
    //       label: strings.cancel,
    //       onClick: () => { }
    //     },
    //     {
    //       label: 'OK',
    //       onClick: () => this.resetCounter(counter)
    //     }
    //   ]
    // });


  }

  resetCounter() {
    var counter = this.state.counterForAction
    counter.current_num = counter.begin_num;
    counter.nbResets = 0;
    this.setState({ counters: this.state.counters, confirmAction: null, counterForAction: null })
  }


  duplicateCounter(counterOrig) {


    if (!Utils.hasValidSubscription() && this.state.counters.length > 2) {
      this.setState({ showRestrictedFeature: true })
      return;
    }


    var counter = JSON.parse(JSON.stringify(counterOrig));

    counter.position = this.state.counters.length;


    counter.id_on_server = 0;
    counter.unique_id_on_client = Math.random().toString(36).slice(2);
    counter.updated_at_on_client = Utils.getTime();
    counter.updatedAtOnServer = 0;



    var tmpComments = []
    for (var y = 0; y < counterOrig.comments.length; y++) {
      var comment = JSON.parse(JSON.stringify(counterOrig.comments[y]));


      comment.id_on_server = 0;
      comment.unique_id_on_client = Math.random().toString(36).slice(2);
      comment.updated_at_on_client = Utils.getTime();
      comment.updatedAtOnServer = 0;

      tmpComments.push(comment)
    }
    counter.comments = tmpComments;

    this.projInProgress.counters.push(counter);







    this.setState({ counters: this.getCurrentCountersOrdered() })


  }

  /*
    saveOnServer() {
      this.projInProgress.updated_at_on_client = Utils.getTime();
      ProjectUtils.sendUpdateToServer(this.projInProgress, (event) => {

      })
      .then((response) => {this.setCountersId(response)})
    }*/

  onSaveCounter(name, max, autoInc, showReset, comments, averageStitches, begin) {

    var counter;
    if (this.state.adding) {
      counter = ProjectUtils.createEmptyCounter(0, this.numPart, this.state.counters.length);
      //counter.position = this.state.counters.length;
      this.projInProgress.counters.push(counter);
    }
    else
      counter = this.state.editingCounter;

    counter.name = name;
    counter.max_num = max;
    counter.auto_inc = autoInc;
    counter.show_reset = showReset;
    if (begin > counter.current_num)
      counter.current_num = begin;
    counter.begin_num = begin;

    counter.average_stitches = averageStitches;
    counter.comments = comments;

    counter.updated_at_on_client = Utils.getTime();



    //for compatibility old versions
    this.projInProgress.average_stitches = averageStitches

    this.setState({ counters: this.getCurrentCountersOrdered() })

    this.sendUpdateToServer(false)
    this.onClose();
  }


  getNbSecondaryCountersForAllParts() {
    var nb = 0;

    for (var i = 0; i < this.projInProgress.counters.length; i++) {
      if (this.projInProgress.counters[i].deleted != 1 && this.projInProgress.counters[i].is_global_counter !== 1)
        nb++;
    }

    return nb;
  }

  addCounter() {

    if (!Utils.hasValidSubscription() && this.getNbSecondaryCountersForAllParts() >= 2) {
      this.setState({ showRestrictedFeature: true })
      return;
    }
    //alert(this.props.match.path)
    this.setState({ adding: true });
    /*  this.props.history.push({
        pathname: `${this.props.match.path}/EditCounter`,
        state: {counter: this.state.adding
                ? ProjectUtils.createEmptyCounter(0, this.numPart)
                : Utils.copyObject(this.state.editingCounter) }
      })

  */

  }


  changeVisibilityCounter(counter) {

    counter.hidden = counter.hidden == 1 ? 0 : 1;
    this.setState({ counters: this.state.counters.sort((a, b) => (a.position > b.position) ? 1 : -1) })

    this.sendUpdateToServer(false)

  }


  onMoveEnd(data) {

    var counters = []

    counters.push(this.state.counters[0])

    //alert(JSON.stringify(data))
    counters.push(...data)

    for (var i = 0; i < data.length; i++) {

      var counter = data[i];
      counter.position = i + 1;

      counter.updated_at_on_client = Utils.getTime();
    }


    this.setState({ counters: counters.sort((a, b) => (a.position > b.position) ? 1 : -1) })


    this.sendUpdateToServer(false)
  }


  tabClicked(tabNum) {


    if (tabNum == this.getNbTabs()) {
      ProjectUtils.createNewPart(this.projInProgress);

      this.numPart = this.numPart + 1;



      this.setState({ counters: this.getCurrentCountersOrdered() })


      this.sendUpdateToServer(false)

    }
    else {
      this.numPart = tabNum;



      this.setState({ counters: this.getCurrentCountersOrdered() })
    }

  }


  renderMenu(counter) {
    return (
      <TouchableOpacity
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 5000,
          justifyContent: "center",
          alignItems: 'center',
          backgroundColor: "rgba(128, 128, 128, 0.7)"
        }}
        onPress={() => this.setState({ showMenuForCounter: null })}
      >
        <View
          style={{
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "#ccc",
            borderRadius: 8,
            margin: 16
          }}
        >
          <TouchableOpacity
            style={styles.menuElem}
            onPress={() => {
              this.changeVisibilityCounter(counter); this.setState({ showMenuForCounter: null })
            }}
          >
            <Text style={styles.menuOptionText}>{counter.hidden == 1 ? strings.show : strings.hide}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.menuElem}
            onPress={() => {
              this.duplicateCounter(counter); this.setState({ showMenuForCounter: null })
            }}
          >
            <Text style={styles.menuOptionText}>{strings.duplicate}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.menuElem}
            onPress={() => {
              this.askResetCounter(counter); this.setState({ showMenuForCounter: null })
            }}
          >
            <Text style={styles.menuOptionText}>{strings.reset_counter}</Text>
          </TouchableOpacity>


          <TouchableOpacity
            style={styles.menuElem}
            onPress={() => {
              this.setState({ showMenuForCounter: null })
            }}
          >
            <Text style={styles.menuOptionText}>{strings.long_press_position}</Text>
          </TouchableOpacity>


        </View>


      </TouchableOpacity>
    );
  }




  renderTabs() {

    var tabs = [];
    var nbTabs = this.getNbTabs();

    var maxVisibleTabs = 10
    var tabWidth = 50


    var max = nbTabs
    if (!this.projInProgress.rc_pattern_id || this.projInProgress.rc_pattern_id == 0) {
      max += 1
    }

    for (let i = 0; i < max; i++) {

      var name = ''

      if (!this.projInProgress.rc_pattern_id || this.projInProgress.rc_pattern_id == 0) {
        name = i == nbTabs ? "+" : strings.part + " " + (i + 1)
      } else {
        name = strings.part + " " + (i + 1)
      }
      var selectedBgStyle = (i == this.numPart) ? {
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,
        elevation: 8,
      } : {}

      tabs.push(

        <TouchableOpacity key={"tabview_key" + i}
          style={[{
            paddingVertical: 6,
            paddingHorizontal: 12,
            //marginLeft:8,
            height: 44,
            borderRadius: 24,
            justifyContent: 'center',
            alignItems: 'center',
            position: "relative",

          },
            selectedBgStyle]}
          onPress={() => this.tabClicked(i)}>

          {false && i != 0 && (<View style={{ height: 15, width: 1, backgroundColor: "#ddd", marginRight: 12 }} />)}
          <Text style={{ textAlign: 'center', color: i == this.numPart ? '#2ba5f7' : '#ccc', fontWeight: i == this.numPart || i == nbTabs ? 'bold' : 'normal' }}>{name}</Text>
        </TouchableOpacity>

      );
    }





    return (

      <ScrollView

        style={{ flexGrow: 0, width: '100%', height: 60, padding: 8 }}
        contentContainerStyle={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        horizontal={true}>


        {tabs}

      </ScrollView>

    )
  }

  renderItem(item, index) {
    // alert(item.name)
    var currentStyle = { borderBottomWidth: 0.5, borderColor: '#ccc', padding: 10 }
    var tintColor = { tintColor: '#ccc' }
    /// var invisibleStyle = item.hidden ? {backgroundColor:'#ddd'} : {}
    // console.debug(`pingoulog:item=${JSON.stringify(item)}`);
    return (
      <TouchableOpacity
        onPress={() => this.setState({ editingCounter: item })}
        style={{
          flex: 1,
          marginBottom: 1,
          padding: 8,
        }}
      >


        {/* <Text style={{fontSize:10, color:'#888'}}>{index == 0 ? strings.global_counter : strings.secondary_counter}</Text> */}


        <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1, marginTop: 8, marginBottom: 8 }}>


          <Image
            style={[
              { width: 60, height: 30 }
            ]}
            resizeMode="contain"
            urlOnServer={index == 0 ? '/imgs/icon-counter-main.png' : '/imgs/icon-counter-sec.png'}
          />

          <Text style={styles.titleText}>
            {item.name && item.name.length > 0
              ? item.name
              : index == 0 ? strings.global_counter : strings.secondary_counter}
          </Text>


          {item.hidden == 1 && (<TouchableOpacity style={{ paddingLeft: 5, paddingRight: 5 }} onPress={() => this.setState({ showMenuForCounter: item })}>
            <Image
              style={[
                { width: 20, height: 20 },
                tintColor
              ]}
              resizeMode="contain"
              urlOnServer={'/imgs/hidden.png'}
            />
          </TouchableOpacity>)}



          {index == 0 && (<TouchableOpacity style={{ paddingLeft: 5, paddingRight: 5 }} onPress={() => this.askResetCounter(item)}><Image
            style={[
              { width: 20, height: 20, marginLeft: 5, marginRight: 5 },
              tintColor
            ]}
            resizeMode="contain"
            urlOnServer={'/imgs/reset.png'}

          /></TouchableOpacity>)}

          {index != 0 && this.state.expertMode == true && (<TouchableOpacity style={{ paddingLeft: 5, paddingRight: 5 }} onPress={() => this.setState({ showMenuForCounter: item })}><Image
            style={[
              { width: 20, height: 20, marginLeft: 5, marginRight: 5 }
            ]}
            resizeMode="contain"
            urlOnServer={'/imgs/lightning.png'}
          /></TouchableOpacity>)}






          <Image
            style={[
              { width: 20, height: 20, marginLeft: 5, marginRight: 5 }
              ,
              tintColor
            ]}
            resizeMode="contain"
            urlOnServer={'/imgs/pencil.png'}
          />





          {
            index != 0 &&
            <TouchableOpacity style={{ paddingLeft: 5, paddingRight: 5 }} onPress={() => this.askDeleteCounter(item)}>
              <Image
                style={[
                  { width: 20, height: 20, tintColor: '#2ba5f7' },
                  tintColor
                ]}
                resizeMode="contain"
                urlOnServer={'/imgs/bin.png'}
              />
            </TouchableOpacity>
          }
          {index == 0 && <View style={{ width: 30 }} />}

        </View>
      </TouchableOpacity>
    );
  }


  renderAdd() {

    // console.debug(`pingoulog:item=${JSON.stringify(item)}`);
    return (
      <TouchableOpacity onPress={() => this.addCounter()} style={{ padding: 10 }}>

        {/* <Text style={{fontSize:10, color:'#ccc'}}>{ strings.secondary_counter }</Text> */}


        <TouchableOpacity onPress={() => this.addCounter()} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1, marginTop: 8, marginBottom: 8 }}>


          <Image
            style={[
              { width: 60, height: 30, opacity: 0.4 }
            ]}
            resizeMode="contain"
            urlOnServer={'/imgs/icon-counter-sec.png'}
          />

          <Text style={[styles.titleText, { color: '#ccc' }]}>
            {strings.secondary_counter}
          </Text>



          <View style={{ paddingRight: 5, width: 40, justifyContent: "center", alignItems: "center", }}>
            <Image
              style={{ width: 24, height: 24 }}
              urlOnServer={'/imgs/plus_bleu.png'}
            />
            <Text style={{ color: "#2ba5f7", fontSize: 10, paddingTop: 4, textAlign: "center" }}>{strings.add} {strings.counter}</Text>
          </View>





        </TouchableOpacity>
      </TouchableOpacity>
    );
  }

  renderLoading() {
    return (
      <View
        pointerEvents="none"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}
      >
        <Text style={{ color: "white", fontSize: 18, alignSelf: "center" }}>
          {strings.loading}
        </Text>
      </View>
    );
  }



  renderEdit() {
    return (
      <View style={{ flex: 1 }}>
        <EditCounter
          ref={(ref) => { this.editCounterRef = ref; }}
          navigation={this.props.navigation}
          counter={
            this.state.adding
              ? ProjectUtils.createEmptyCounter(0, this.numPart, this.state.counters.length, 1)
              : Utils.copyObject(this.state.editingCounter)
          }
          onSave={(name, max, autoInc, showReset, comments, averageStitches, begin) =>
            this.onSaveCounter(
              name,
              max,
              autoInc,
              showReset,
              comments,
              averageStitches,
              begin
            )
          }
          onClose={() => this.onClose()}
          isGlobalCounter={
            !this.state.adding && this.state.editingCounter == this.state.counters[0] ? true : false
          }
          isPatternPreviewProject={this.projInProgress.isPatternPreviewProject == 1 ? true : false}
          history={this.props.history}
        />
      </View>
    );
  }

  test2 = () => {
    alert('test2!')
  }

  renderDeletePart() {
    return (
      <TouchableOpacity style={{
        position: "absolute",
        bottom: 16,
        left: 12,
        justifyContent: "center",
        alignItems: "center" }} onPress={() => this.askDeletePartie()}>
        <Image
          style={[
            { width: 18, height: 18, tintColor: '#2ba5f7' }
          ]}
          resizeMode="contain"
          urlOnServer={'/imgs/bin.png'}
        />
        <Text style={{
          color: "#a0a0a0",
          marginTop: 8,
          fontSize: 10,
        }}>Delete part</Text>
      </TouchableOpacity>
    )

  }

  renderConfirmAction() {
    var toConfirm = this.state.confirmAction;
    return (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: 600,
        backgroundColor: '#f6f6f6',
        borderRadius: 12,
        padding: 12,
        height: Dimensions.get('window').height - HEADER_HEIGHT - 100
         }}>
          <Text style={{ fontSize: 24, color: "#333", marginVertical: 12 }}>{strings.areYouSure}</Text>
          <Text style={{ fontSize: 16, color: "#555", marginVertical: 12}}>{this.confirmActions[toConfirm].message}</Text>
          <View style={{ flexDirection: "row", justifyContent: "space-around", width: "50%", marginVertical: 12 }}>
            <TouchableOpacity
              style={{
                padding: 12,
                width: 100,
                borderRadius: 24,
                backgroundColor: "#a0a0a0",
                justifyContent: "center",
                alignItems: "center"
              }}
             onPress={() => this.setState({ confirmAction: null, counterForAction: null })}>
              <Text style={{ fontSize: 16, color: "#fff" }}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
            style={{
                padding: 12,
                width: 100,
                borderRadius: 24,
                backgroundColor: "#2ba5f7",
                justifyContent: "center",
                alignItems: "center"
              }}
              onPress={this.confirmActions[toConfirm].action}>
              <Text style={{ fontSize: 16, color: "#fff" }}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
    )
  }

  render() {
    /* console.debug(
       `pingoulog:this.state=${JSON.stringify(this.state)}`
     );*/

    //  alert(JSON.stringify(this.state))

    if (this.state.editingCounter != null || this.state.adding == true) {

      return (<View>
        {this.renderEdit()}
      </View>
      )
    }

    var newArray = [...this.state.counters]
    console.log("counters", newArray)
    newArray.shift()
    console.log("counters shift", newArray)
    return (
      <View style={{ flex: 1, zIndex: 10000, justifyContent: 'center', alignItems: 'center', height: Dimensions.get('window').height - HEADER_HEIGHT }}>
{
  this.state.confirmAction ? this.renderConfirmAction() :

        <View style={{ width: 600, backgroundColor: '#f6f6f6', borderRadius: 12, padding: 12, height: Dimensions.get('window').height - HEADER_HEIGHT - 100 }}>
          {this.renderTabs()}



          <ScrollView style={{width: '100%', marginBottom: 12 }}>
          <View style={styles.container}>
            <Text style={{ fontSize: 10, color: '#a0a0a0' }}>{strings.global_counter}</Text>
            {this.renderItem(this.state.counters[0], 0)}
          </View>

            <View style={{ marginBottom: 12 }}>
              <View style={styles.container}>
                <Text style={{ fontSize: 10, color: '#a0a0a0' }}>{strings.secondary_counter}</Text>
                {newArray.map((item, index) => this.renderItem(item, index + 1))}

                {!this.state.adding &&
                  this.state.editingCounter == null &&
                  this.renderAdd()}
              </View>
            </View>
          </ScrollView>





          {/* <View style={{flex:1}}>

        </View> */}


          {ProjectUtils.getNbParts(this.projInProgress) > 1 && (!this.projInProgress.rc_pattern_id || this.projInProgress.rc_pattern_id == 0) && this.renderDeletePart()}


          {this.state.showMenuForCounter && this.renderMenu(this.state.showMenuForCounter)}

          {this.showKindness && (<TouchableOpacity style={{ backgroundColor: SECONDARY_COLOR }} onPress={() => this.setState({ showRestrictedFeature: true })}>
            <Text style={{ textAlign: 'center', padding: 8, marginBottom: 8, color: '#fff' }}>{strings.counters_not_limited_beta}</Text>
          </TouchableOpacity>)}



          <View style={{ flex: 0, flexDirection: "row", justifyContent: "flex-end", alignSelf: "flex-end", paddingHorizontal: 15, paddingVertical: 25 }}>

            <TouchableOpacity onPress={() => this.sendUpdateToServer(true)}>
              <Text style={{ marginRight: 20, fontSize: 16, color: "#c3c3c3", fontWeight: "bold", alignSelf: 'center', textAlign: "center" }}>{strings.back.toUpperCase()}</Text>
            </TouchableOpacity>


            {/*   <TouchableOpacity onPress={() => this.sendUpdateToServer()}>
                  <Text style={{marginHorizontal:0, fontSize:16, color: "#2ba5f7", fontWeight: "bold", alignSelf:'center', textAlign:"center"}}>{strings.save.toUpperCase()}</Text>
              </TouchableOpacity> */}
          </View>

        </View>
      }
      {/* {this.renderLoading()} */}
        {this.state.loadingSave && this.renderLoading()}
      </View>
    );
  }
}

export default withRouter(EditCounterList);



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginHorizontal: 12,
    borderRadius: 12,
    marginVertical: 8,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 12,
    padding: 18,
  },


  descText: {
    marginTop: 15,
    padding: 10,
    paddingBottom: 60,
    color: "#555",
  },


  titleText: {
    fontSize: 14,
    marginRight: 8,
    marginLeft: 8,
    flex: 1,
    alignSelf: "center",
    color: "#555",
  },
  imgStitch: {
    width: 40,
    height: 40
  },
  imgPlay: {
    alignSelf: "center"
  },
  menuOptionText: {
    color: '#888',
    textAlign: 'center'
  },

  menuElem: {
    borderBottomWidth: 0.5, padding: 12, borderColor: "#ccc"
  }
});
