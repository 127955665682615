import React from 'react';
import useToken from '../hooks/useToken';
import { useInterval } from '../hooks/useInterval';
import useCurrentProject from '../hooks/useCurrentProject';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory, useLocation } from 'react-router-dom';
import Utils from '../utils/utils'
import ProjectUtils from '../utils/projectUtils'
import Counter from '../components/Counter'
import ProjectOption from '../components/ProjectOption'
import ShowComment from '../components/ShowComment'
import PDFDocument from '../components/PDFDocument'
import PartFinished from '../components/PartFinished'
import SelectPart from '../components/SelectPart'
import Loading from '../components/Loading'
import PatternPreview from '../components/generator/PatternPreview'

import fetchClient from "../utils/http-common";
import ProjectService from "../services/ProjectService";
import EditCounterList from "./EditCounterList"
//import { getLoginUri } from "../utils/ravelryOauth"
import Image from "../components/Image"
import "../config/styles.css"
import { Beforeunload } from 'react-beforeunload';
import strings from "../config/strings";

import {

  Text,
  TouchableOpacity,
  View,

} from 'react-native';

export default function ViewProject(props) {


  const { projectId } = useParams();

  const location = useLocation();
  const [project, setProject] = useState();
  const [counters, setCounters] = useState([]);
  const { token, setToken } = useToken();
  const { currentProjectId, setCurrentProjectId } = useCurrentProject();
  const [ finished, setFinished ] = useState(false);
  const [patternUrl, setPatternUrl] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState();
  const [ showEditCounters, setShowEditCounters ] = useState(false);
  const [ showPartFinished, setShowPartFinished ] = useState(false);
  const [ showSelectPart, setShowSelectPart ] = useState(false);
  const [currentPage, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [currentComments, setComments] = useState([]);
  const [currentZoom, setZoom] = useState(1);
  const [numPages, setNumPages] = useState(1);


  const [drawingEnabled, setDrawingEnabled ] = useState(false);
  const [highlighterHorizontalEnabled, setHighlighterHorizontalEnabled] = useState(localStorage.getItem('highlighterHorizontalEnabled', false) || false);
  const [highlighterVerticalEnabled, setHighlighterVerticalEnabled] = useState(localStorage.getItem('highlighterVerticalEnabled', false) || false);

  const [isRcPattern, setIsRcPattern] = useState(false)
  const [rcPattern, setRcPattern] = useState();
  const [currentPart, setCurrentPart] = useState()

  var lastUpdateAt = 0;

  const history = useHistory();


  const handleUnload = () => {

     if (!showEditCounters && project && project.updated_at_on_client > lastUpdateAt) {
      console.log('unloadiiiiiiiing')
        sendUpdateToServer()
      }
  }

  useEffect(() => history.listen(() => {
        console.log('leaviiiiiiing')
      if (!showEditCounters && project && project.updated_at_on_client > lastUpdateAt) {
        sendUpdateToServer()
      }
    }), [])


  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);


  const routeImportPattern = (projectId) =>{
    let path = `/UploadLocalFile/${projectId}`;
    history.push(path);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {

   // window.addEventListener('beforeunload', () => console.log('leaving'));
    let mounted = true;

    fetchClient.get(`${SERVER_API_URL}/user_projects/${projectId}.json`)
    .then(data => {
  //debugger;
      const project = data.data
      //const page = 1
    //  alert(JSON.stringify(project))
        if(mounted) {
          setProject(project)
          setPage(project.current_page_nb_web && project.current_page_nb_web > 0 ? project.current_page_nb_web : 1)

          //alert(JSON.stringify(`${SERVER_API_URL}/${project.pdf_pattern.url}`))
          if (project.pdf_pattern && project.pdf_pattern.url && project.pdf_pattern.url.indexOf("missing") == -1)
            setPatternUrl(`${SERVER_API_URL}/${project.pdf_pattern.url}`)

          let tmpCounters = ProjectUtils.getCounters(project, project.current_partie);
          setCounters(tmpCounters)
          getAllComments(tmpCounters)
          setCurrentProjectId(project.id)
          setElapsedTime(project.time_elapsed)
          setFinished(project.finished === 1)
          setLoading(false)
          setCurrentPart(project.current_partie != -1 ? project.current_partie : 0)

          if (project.rc_pattern_id && project.rc_pattern_id != 0) {
            setIsRcPattern(true)
            setRcPattern(location.state.pattern)

          }
        }
      })
    .catch(error => {alert('err ' + error.message);
    setLoading(false) })

    return () => mounted = false;
  }, [])

  useEffect(() => {
    console.log(currentPart)
  }, [currentPart]);

  useInterval(() => {
    setElapsedTime(elapsedTime + 1)


    project.time_elapsed = elapsedTime
    project.updated_at_on_client = Utils.getTime()

    if (elapsedTime % 10 == 0) {
      sendUpdateToServer();
    }
  }, timerRunning ? 1000 : null);



  const getCounterAtPos = (proj, position) => {

    for (var i = 0; i < proj.counters.length; i++) {
      if (proj.counters[i].deleted !== 1 && proj.counters[i].position === position && proj.counters[i].num_part === proj.current_partie)
        return proj.counters[i];
    }

    alert('ERROR ' + position)
  }

  const incCounter = (counterPos, inc) => {
    console.log("current project", project)
    var counter = getCounterAtPos(project, counterPos)

    var counterIndex = counterPos;
    if (counterIndex === 0 && counter.current_num <= counter.begin_num && inc < 0)
      return;

    if (counter.current_num >= counter.max_num && inc > 0 && counter.max_num !== -1) {
      if (counterIndex === 0) {


        var nbParts = ProjectUtils.getNbParts(project);

        if (nbParts === 1 || project.current_partie === nbParts - 1) {

          project.finished = 1;

          setFinished(true)
        }
        else {

         // alert('show parts')
          setShowPartFinished(true)
        }
      }
      else {
        counter.current_num = counter.begin_num;
        counter.nb_resets = counter.nb_resets ? counter.nb_resets + 1 : 1;
      }
    } else if (counter.current_num === counter.begin_num && inc < 0) {
      counter.current_num = counter.max_num;
      counter.nb_resets = counter.nb_resets ? counter.nb_resets - 1 : -1;
    }
    else {
       counter.current_num += inc;
    }

    counter.updated_at_on_client = Utils.getTime()

    return counter
      //alert(JSON.stringify(counter.current_num))
  }

  const onPageChange = (page, increase) => {

    var newPage = increase? page + 1 : page - 1

    if (newPage <= 0)
      newPage = 1

    setPage(newPage)
    project.current_page_nb_web = newPage

    sendUpdateToServer()
  }

  const onZoomChange = (zoom, increase) => {
    var newZoom = increase? zoom + 0.1 : zoom - 0.1

    if (newZoom > 0.6 && newZoom < 1.5) {
      setZoom(newZoom)
    } else {
      setZoom(zoom)
    }
  }

  const renderPatternOrImport = (patternUrl,projectId,page) => {
   // alert(patternUrl)
    if (patternUrl) {
      return (
        <div className="PDFContainer" >
          <div className="ZoomAndPageContainer" >
              <div className="PageHandlerContainer" style={{zIndex:40000}}>
                    <button className="OptionButton" onClick={() => onPageChange(page, false)}>
                        <Image urlOnServer={'/imgs/arr_left.png'} style={{width: 15, height: 15, margin:0}}/>
                    </button>
                    <p>{page}</p>
                  {(page < numPages) && <button className="OptionButton" onClick={() => onPageChange(page, true)}>
                        <Image urlOnServer={'/imgs/arr_right.png'} style={{width: 15, height: 15, margin:0}}/>
                    </button>   }
              </div>
              <div className="PageHandlerContainer" style={{zIndex:40000}}>
                    <button className="OptionButton" onClick={() => onZoomChange(currentZoom, false)}>
                        <Image urlOnServer={'/imgs/zoom-moins.png'} style={{width: 30, height: 30, margin:0}}/>
                    </button>
                    <button className="OptionButton" onClick={() => onZoomChange(currentZoom, true)}>
                        <Image urlOnServer={'/imgs/zoom-plus.png'} style={{width: 30, height: 30, margin:0}}/>
                    </button>
              </div>
          </div>
          <div>
            <PDFDocument  file={patternUrl}
                page={page}
                zoom={currentZoom}
                highlighterHorizontalEnabled={highlighterHorizontalEnabled}
                highlighterVerticalEnabled={highlighterVerticalEnabled}
                numPages={(numPages) => {
                  setNumPages(numPages)
                }

                }
                userProjectId={projectId}
                drawingEnabled={drawingEnabled}
                />


            </div>
        </div>
      )
    } else {
      return (
        <div className="NoPatternBtns" >
            <button className="ImportPatternBtn" onClick={() => routeImportPattern(projectId)}>
            {strings.import_pattern}
            </button>
          {/*  <button className="ImportPatternBtn" onClick={() => getLoginUri()}>
            Import Ravelry Pattern
            </button> */}
        </div>
      )

    }
  }

  const renderRcPatternContent = (pattern, part) => {
    return (
      <PatternPreview
        key={part}
        pattern={pattern}
        currentPart={part}
        previewProject={project}
      />
    )
  }

  const sendUpdateToServer = () => {
  
    var lastUpdateTmp = Utils.getTime()
    ProjectUtils.sendUpdateToServer(project)
    .then((response) => {

     // alert(JSON.stringify(response))
      //setMessage(response.data.message);
     // props.onSuccess(response)
      lastUpdateAt = lastUpdateTmp
    })

    .catch((error) => {

      alert(error.message)

    });
  }

  const onCounterChange = (counterPos, inc) => {
    if (inc < 0 && finished) {
      setFinished(false)
      return;
    }

    incCounter(counterPos, inc);



    if (counterPos === 0) {
      for (var i = 0; i < counters.length; i++) {
        if (counters[i].position !== 0 && counters[i].auto_inc === 1 && counters[i].deleted !== 1)
          incCounter(counters[i].position, inc);
      }
    }


    var tmpCounters = ProjectUtils.getCounters(project, project.current_partie)
    setCounters(tmpCounters)
    getAllComments(tmpCounters)


    sendUpdateToServer();
  };


  const test = () => {
    alert('test!')
  }

  const currentCommentFilter = (comment, currentNb) => {
    console.log(comment)
    if (!comment.txt)
      return false
    if (comment.show_at_end > 0) {
      if (comment.show_at_end >= currentNb && comment.show_at <= currentNb)
        return true
      return false
    }
    if (comment.show_at == currentNb)
      return true
    return false
  }

  const getComments = (item) => {
    var currentNb = item.current_num
    // var currentComments = item.comments.map( comment => comment.show_at == currentNb && comment.txt).filter(function (el) { return el != false; })
    //var currentComments = item.comments.map( (comment, currentNb) => currentCommentFilter(comment, currentNb)).filter(function (el) { return el != false; })
    var currentComments = item.comments.filter( comment => currentCommentFilter(comment, currentNb) )
    console.log("currentComments", currentComments)
    if (currentComments.length > 0) { return currentComments }
  }

  const getAllComments = (counters) => {
    var allComments =
    counters.map( item => getComments(item) && getComments(item)).filter(function (el) { return el != null; })
    setComments(allComments)
  }

  const renderEditCounters = () => {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: 10000,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems:'center',
          backgroundColor: "rgba(128, 128, 128, 0.7)",
        }}

        >
        <EditCounterList project={project}
        onClose={() => { setShowEditCounters(false)

          console.log(project)
          let tmpCounters = ProjectUtils.getCounters(project, project.current_partie)
          setCounters(tmpCounters)
          getAllComments(tmpCounters)
        }}/>

        </div>

      )
  }

  const renderGoBack = () => {
    return (
      <TouchableOpacity
      style={{justifyContent: "center", alignItems: "flex-start", alignSelf: "flex-end", width: 32, marginBottom: 24}}
      onPress={() => onBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
        <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "center", fontSize: 10}}>{strings.back}</Text>
      </TouchableOpacity>
    )
}

  const onBack = () => {
    history.push({
      pathname: `/created-pattern-details/${rcPattern.id}`,
      state: { pattern: rcPattern }
    })
  }

  const changePart = (num) =>  {


    project.current_partie = num;

    var c = ProjectUtils.getCounters(project, project.current_partie)

    console.log(c)
    setCounters(c)
    getAllComments(c)
    setCurrentPart(num)
  }



  const switchHighlighterHorizontal = () =>  {
    if (!parseInt(localStorage.getItem('premiumExpiresAt', 0))
    || parseInt(localStorage.getItem('premiumExpiresAt', 0)) < Date.now()) {
      alert(strings.premium_feature)
      return
    }
    setHighlighterHorizontalEnabled(!highlighterHorizontalEnabled)
    localStorage.setItem('highlighterHorizontalEnabled', !highlighterHorizontalEnabled)
    localStorage.setItem('highlighterHorizontal', -100)
  }

  const switchHighlighterVertical = () =>  {
    if (!parseInt(localStorage.getItem('premiumExpiresAt', 0))
    || parseInt(localStorage.getItem('premiumExpiresAt', 0)) < Date.now()) {
      alert(strings.premium_feature)
      return
    }
    setHighlighterVerticalEnabled(!highlighterVerticalEnabled)
    localStorage.setItem('highlighterVerticalEnabled', !highlighterVerticalEnabled)
  }

  const switchDrawing = () =>  {
    if (!parseInt(localStorage.getItem('premiumExpiresAt', 0))
    || parseInt(localStorage.getItem('premiumExpiresAt', 0)) < Date.now()) {
      alert(strings.premium_feature)
      return
    }
    setDrawingEnabled(!drawingEnabled)
  }


  if (loading)
    return (<Loading/>
      )


  const patternUrltest = "/imgs/nid_ange_pingouin.pdf"
  return (
        <div className="ProjectContentContainer" >

            <div className="CountersContainer" >

            { isRcPattern && rcPattern && renderGoBack()}

                <ProjectOption
                  onClick={() => setShowEditCounters(true)}
                  picUrl={'/imgs/chart_settings.png'}
                  speId={"EditCounters"}
                  smallText={true}
                  detail= {strings.options}
                  >
                </ProjectOption>
                <ProjectOption
                  onClick={() => setTimerRunning(!timerRunning)}
                  picUrl={timerRunning?'/imgs/timer-on-btn.png':'/imgs/timer-off-btn.png'}
                  detail= {Utils.convertSecondsToTimer(elapsedTime)}
                  >
                </ProjectOption>
                <ProjectOption
                  onClick={() => routeImportPattern(projectId)}
                  picUrl={'/imgs/changer-patron.png'}
                  smallText={true}
                  detail= {"PDF"}
                  >
                </ProjectOption>

                {!isRcPattern && !rcPattern && patternUrl &&<ProjectOption
                  onClick={() => switchHighlighterHorizontal()}
                  picUrl={highlighterHorizontalEnabled ? '/imgs/highlight_horiz_on.png' : '/imgs/highlight_horiz_off.png'}
                  smallText={true}
                  detail= {strings.highlighter}
                  >
                </ProjectOption> }
                {!isRcPattern && !rcPattern && patternUrl && <ProjectOption
                  onClick={() => switchHighlighterVertical()}
                  picUrl={highlighterVerticalEnabled ? '/imgs/highlight_vertic_on.png' : '/imgs/highlight_vertic_off.png'}
                  smallText={true}
                  detail= {strings.highlighter}
                  >
                </ProjectOption>}

              {!isRcPattern && !rcPattern && patternUrl && <ProjectOption
                  onClick={() => switchDrawing()}
                  smallText={true}
                  detail= {strings.draw}
                  picUrl={drawingEnabled ? '/imgs/draw_circle_on.png' : '/imgs/draw_circle_off.png'}
                  >
                </ProjectOption> }
                {currentComments.length > 0 && <ShowComment
                  currentComments = {currentComments}
                  ></ShowComment>}
            </div>

            {(isRcPattern && rcPattern) ? renderRcPatternContent(rcPattern, currentPart): renderPatternOrImport(patternUrl, projectId, currentPage)}

            {/* {renderPatternOrImport(patternUrl, projectId, currentPage)} */}


            <div className="CountersContainer" >
            { ProjectUtils.getNbParts(project) > 1 && <button className="PartButton" onClick={() => setShowSelectPart(true)}>
            {strings.select_part}
            </button>}
                {counters.map(item => <Counter
                    key={'counter_' + item.id}
                    name={item.name}
                    isMain={item.is_global_counter}
                    value={item.current_num}
                    nbResets={item.nb_resets}
                    showReset={item.show_reset}
                    maxNum = {item.max_num}
                    position={item.position}
                    isLinked={item.auto_inc === 1}
                    onChange={(inc, counterPos) => onCounterChange(counterPos, inc)}
                    onToggleLinked={() => {
                      item.auto_inc = item.auto_inc === 1 ? 0 : 1
                      sendUpdateToServer()
                      let tmpCounters = ProjectUtils.getCounters(project, project.current_partie)
                      setCounters(tmpCounters)
                      getAllComments(tmpCounters)
                    }}

                    /> )}
            </div>

            {showEditCounters && renderEditCounters()}


            {showPartFinished && <PartFinished onClose={() => setShowPartFinished(false)} goToNextPart={() => {
              changePart(project.current_partie + 1)
              setShowPartFinished(false)
            }} />}

            {showSelectPart && <SelectPart project={project}  onClose={() => setShowSelectPart(false)} onChangePart={(numPart) => {
              changePart(numPart)
              setShowSelectPart(false)
            }} />}
        </div>

    );
}
