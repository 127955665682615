import Utils from '../utils/utils'
import ProjectService from "../services/ProjectService";

export default class projectUtils {

	static getCounters(project, numPart = 0, includeDeleted = false) {
		if (!project || !project.counters)
			return []

		var counters = []
		for (var i = 0; i < project.counters.length; i++) {
			if (project.counters[i].num_part === numPart && (project.counters[i].deleted !== 1 || includeDeleted)) {
				counters.push(project.counters[i])
			}
		}

		return counters
	}


	static createEmptyProject(name, projectTool) {
		const project = {}
		project.custom_name = name;
		project.unique_id_on_client = Utils.getUID()
		project.updated_at_on_client = Utils.getTime()
		project.time_elapsed = 0
		project.deleted = 0
		project.proj_type_id = -1
		project.is_from_web = 1
		project.project_tool = projectTool
		project.max_lines = 0
		project.average_stitches = -1
		project.counters = [ this.createEmptyCounter(1, 0, 0) ]


		return project

	}

	static createRcLinkedProject(name, projectTool, rcPatternId ) {
		const project = {}
		project.custom_name = name;
		project.unique_id_on_client = Utils.getUID()
		project.updated_at_on_client = Utils.getTime()
		project.time_elapsed = 0
		project.deleted = 1
		project.proj_type_id = -1
		project.is_from_web = 1
		project.project_tool = projectTool
		project.max_lines = 0
		project.average_stitches = -1
		project.counters = [ this.createEmptyCounter(1, 0, 0) ]
		project.rc_pattern_id = rcPatternId
		project.is_pattern_preview_project = 1


		return project

	}


	static createEmptyCounter(isGlobalCounter, numPart, position, autoInc = 0) {
		const counter = {}

		counter.unique_id_on_client = Utils.getUID()
		counter.updated_at_on_client = Utils.getTime()
		counter.is_global_counter = isGlobalCounter
		counter.name = ''
		counter.num_part = numPart
		counter.current_num = 1
		counter.begin_num = 1
		counter.max_num = -1
		counter.average_stitches = -1
		counter.deleted = 0
		counter.comments = []
		counter.auto_inc = autoInc
		counter.position = position
		counter.show_reset = 0
		return counter
	}


	static createEmptyComment() {
		const comment = {}

		comment.unique_id_on_client = Utils.getUID()
		comment.deleted = 0
		comment.updated_at_on_client = Utils.getTime()
		comment.txt = ''
		comment.show_at = 0
		comment.show_at_end = 0

		return comment
	}

	static getNonDeletedCounters(proj, numPart = 0) {

		if (numPart == -1)
			return []
		let nonDeleted = [];
	    for (var i = 0; i < proj.counters.length; i++) {
	      if (proj.counters[i].deleted !== 1 && proj.counters[i].num_part === numPart)
	        nonDeleted.push(proj.counters[i]);
	    }

	    return nonDeleted;
	}


	static createNewPart(proj) {
		console.log("in create new part, proj:", proj)
		var nbParts = this.getNbParts(proj);

		var counter = this.createEmptyCounter(1, nbParts, 0)


		proj.counters.push(counter);
		proj.finished = 0;

		//do not send to server or it will duplicate counters, because it is saved again just right after
		//this.sendUpdateToServer(proj)

	}

	static getNbParts(proj) {

		var nbParts = -1;
		for (var i = 0; i < proj.counters.length; i++) {
	      if (proj.counters[i].num_part > nbParts && proj.counters[i].deleted !== 1)
	      	nbParts = proj.counters[i].num_part;
	    }

	    return nbParts + 1
	}


	static sendUpdateToServer = (project) => {


		project.updated_at_on_client = Utils.getTime()
		const projectToSend = {...project}

		delete projectToSend.pdf_pattern
		delete projectToSend.pattern_modified_at
		delete projectToSend.pattern_url

	    return ProjectService.sendUpdateToServer(projectToSend, (event) => {
	      //setProgress(Math.round((100 * event.loaded) / event.total));
	    })

  }

}
